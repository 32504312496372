import React from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import backgroundImage from '../images/Backgrounds/backgroundV1-min.jpg';
import Layout from '../components/layout';
import SEO from '../components/seo';
import COLORS from '../constants/colors';
import { injectIntl } from 'gatsby-plugin-intl';

const useStyles = makeStyles({
  background: {
    padding: '160px 0px 60px 0px',
    background: `url(${backgroundImage}) 50% 0/cover no-repeat`,
    '&>div': {
      width: 'fit-content',
    },
  },
  date: {
    color: 'white',
    display: 'block',
    fontWeight: 'bold',
  },
  dateBottom: {
    color: 'white',
    display: 'block',
    fontWeight: 'bold',
    textAlign: 'right',
    maxWidth: 650,
    paddingTop: 40,
  },
  title: {
    paddingTop: 40,
    paddingBottom: 40,
    maxWidth: 750,
    color: 'white',
    '@media (max-width:600px)': {
      fontSize: 20,
      width: '100%',
      marginBottom: 26,
    },
  },
  newsPost: {
    color: 'white',
    whiteSpace: 'pre-line',
    maxWidth: 650,
    '& img': {
      display: 'block',
      margin: '0 auto',
      width: 'fit-content',
      maxHeight: 450,
      maxWidth: 650,
    },
    '& a': {
      color: COLORS.RED,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: COLORS.RED,
      },
      '&:visited': {
        color: COLORS.RED,
      },
    },
  },
});

// TODO: fix seo component keywords and default keywords

const BlogPostTemplate = ({ data, intl }) => {
  const classes = useStyles();
  const post = data.contentfulBlogPost;
  const { formatMessage } = intl;

  return (
    <Layout>
      <div className={classes.background}>
        <Container maxWidth="xl">
          <SEO
            title={post.title}
            description={post.title}
            keywords={['Hockey']}
          />
          <h2 className={classes.title}>{post.title}</h2>
          <p className={classes.date}>{post.publishDate}</p>
          <span>
            <div
              className={classes.newsPost}
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
            <p className={classes.dateBottom}>
              {formatMessage({ id: 'Blog.publishedBy' })}
              {post.publishDate}
            </p>
          </span>
        </Container>
      </div>
    </Layout>
  );
};

export default injectIntl(BlogPostTemplate);

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
